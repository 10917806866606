import React from 'react'
import { Trans } from '@lingui/react'

import './get-the-app.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { LayoutColumn } from 'client/shared/blocks/layout-column'
import { Heading } from 'client/shared/blocks/heading'
import { MinimalHeader } from 'client/shared/blocks/minimal-header'
import Spacer from 'client/shared/blocks/spacer'

type Props = {
  onClose: () => void | null | undefined
} & DecoratorProps

const GetTheApp: React.FC<Props> = ({ isMobileSize, onClose }: Props) => {
  return (
    <div className="get-the-app">
      <MinimalHeader onClose={onClose} logoKind="default" />
      <LayoutColumn>
        <Spacer size={isMobileSize() ? 24 : 64} />
        <Heading centered>
          <Trans id="shared.gettheapp_header" />
        </Heading>
        <Spacer size={8} />
        <div className="get-the-app__note">
          <Trans id="shared.gettheapp_note" components={[<br />]} />
        </div>
        <Spacer size={isMobileSize() ? 24 : 32} />
        <LayoutColumn size={4} centered>
          <div id="qr_onelink" className="get-the-app__qr" />
        </LayoutColumn>
        <Spacer size="bottom-page" />
      </LayoutColumn>
    </div>
  )
}

export default uiBox(GetTheApp)
