import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import errorBoundary from 'client/shared/decorators/error-boundary'

import GetTheApp from 'client/bookmate/blocks/get-the-app'
import MetaTitle from 'client/shared/blocks/meta-title'
import { MetaDescription } from 'client/shared/blocks/meta-description'

import { Dispatch } from 'shared/types/redux'

import { Exact } from 'types/local/exact-check'

type Props = (Exact<withI18nProps> & {
  onClose: () => void
}) & { dispatch: Dispatch }

class GetTheAppPage extends Component<Props> {
  render() {
    const { onClose } = this.props

    return (
      <>
        {this.renderMeta()}
        <GetTheApp onClose={onClose} />
      </>
    )
  }

  renderMeta() {
    const { i18n } = this.props
    const title = i18n.t`metadata.getteapp_head_title`
    const description = i18n.t`metadata.getteapp_meta_description`

    return (
      <>
        <MetaTitle title={title} />
        <MetaDescription description={description} />
      </>
    )
  }
}

const wrappers = compose(errorBoundary, withI18n({ update: true }))

export default wrappers(GetTheAppPage)
